

















































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue';
import CustomerService from '@/services/customer/customer.service';
import moment from 'moment';

export default Vue.extend({
  created() {
    this.initalValue();
  },
  data() {
    return {
      customerDetail: {} as any,
    };
  },
  methods: {
    async initalValue() {
      const data = await CustomerService.getCustomerDetail(
        this.$route.params.id,
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data.allBills = data.allBills.map((bill: any) => {
        bill.selected = false;
        return bill;
      });
      this.customerDetail = data;
      this.customerDetail.createdAt = moment(
        this.customerDetail.createdAt,
      ).format('YYYY-DD-MM');
      console.log(this.customerDetail);
    },
  },
});
