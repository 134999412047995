import router from '@/router';
import axiosClient from '@/config/axios.configs';
import jwt from 'jsonwebtoken';
import { ILoginForm } from '@/components/LoginForm/interface/LoginForm.interface';

class AuthService {
  async logIn(data: ILoginForm) {
    try {
      const res = await axiosClient.post('auth', data);
      if (res.data.data) {
        const token = res.data.data;
        localStorage.setItem('token', token);
        router.push('/dashboard');
      }
      return res.data;
    } catch (error) {
      console.log('Service: logIn');
      console.log(error);
    }
  }

  logOut() {
    localStorage.removeItem('token');
    router.push('/login');
  }

  isLoggedIn() {
    if (localStorage.getItem('token')) {
      return true;
    }
    return false;
  }

  getTokenPayload() {
    const payload = jwt.decode(localStorage.getItem('token') || '');
    return payload;
  }
}

export default new AuthService();
