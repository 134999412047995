












































import Vue from 'vue';
import authService from '@/services/auth/auth.service';
import NavComponentsList from './NavBarItems';
import Image from '@/assets/ImageUrl';

const currentUser = authService.getTokenPayload();

export default Vue.extend({
  props: {
    drawer: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      adminAvatarUrl: Image.AdminAvatar,
      navComponentsList: NavComponentsList,
      currentUser,
    };
  },
  methods: {
    switchDrawer() {
      this.$emit('switchDrawer', !this.drawer);
    },
  },
});
