













import Vue from 'vue';
export default Vue.extend({
  props: {
    dataTable: {
      required: true,
      type: Array,
    },
  },
  data: () => {
    return {
      series: [
        {
          name: 'Total',
          data: [3, 10, 23, 33, 36, 53, 104],
        },
        {
          name: 'Paid-User',
          data: [0, 0, 3, 6, 13, 13, 49],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: 'area',
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        xaxis: {
          type: 'category',
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'July', 'Jun'],
        },
      },
    };
  },
});
