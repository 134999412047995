














































































































































import Vue from 'vue';
import QuestionPackService from '@/services/default-config/questionPackConf.service';

export default Vue.extend({
  data() {
    return {
      dataLoading: true,
      formEditing: false,
      newMessage: {
        content: '',
        hint: '',
        type: '',
      },
      questionTypes: ['NORMAL', 'OBSTACLE'],
      formData: {
        name: 'Default Question Pack',
        messages: [
          {
            content: 'What did you do yesterday ?',
            hint: '',
            type: 'NORMAL',
          },
          {
            content: 'What will you do today ?',
            hint: '',
            type: 'NORMAL',
          },
          {
            content: 'Do you have any problems ?',
            hint: "If not please say 'no'",
            type: 'OBSTACLE',
          },
        ],
      },
    };
  },
  created() {
    this.initiate();
  },
  methods: {
    async initiate() {
      let data = await QuestionPackService.getDetail();
      if (data.ok) {
        if (!data.data.name) {
          await QuestionPackService.updateDetail(this.formData);
          data = await QuestionPackService.getDetail();
        }
        this.formData = data.data;
      }
      this.dataLoading = false;
    },

    async update() {
      if (this.formEditing) {
        this.dataLoading = true;
        await QuestionPackService.updateDetail(this.formData);
        await this.initiate();
        this.dataLoading = false;
      }
      this.formEditing = !this.formEditing;
    },
    duplicateMessage(index: number) {
      this.formData.messages.push(this.formData.messages[index]);
    },
    deleteMessage(index: number) {
      this.formData.messages.splice(index, 1);
    },
    addMessage() {
      this.formData.messages.push(this.newMessage);
      this.newMessage = {
        content: '',
        hint: '',
        type: '',
      };
    },
  },
});
