import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify);
const config = {
  theme: {
    themes: {
      light: {
        primary: '#47c0b8',
        secondary: '#bfbfbf',
        accent: '#ededed',
        default: '#47c0b8',
        error: '#ffa14a',
        dark: '#333',
      },
    },
  },
};
export default new Vuetify(config);
