<template>
  <v-card height="100%">
    <v-skeleton-loader v-if="dataTable.length == 0" type="table">
    </v-skeleton-loader>
    <div v-else>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      <v-data-table :headers="headers" :items="dataTable" :search="search">
      </v-data-table>
    </div>
  </v-card>
</template>
<script>
export default {
  props: {
    dataTable: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'Workspace',
          align: 'start',
          filterable: false,
          divider: true,
          value: 'name',
        },
        { text: 'Total Members', value: 'members', align: 'center' },
        { text: 'Total Reports', value: 'reports', align: 'center' },
        { text: 'Total Messages', value: 'messages', align: 'center' },
        { text: 'Payment Plan', value: 'plan', align: 'center' },
        { text: 'Paid Amount (US Dollar)', value: 'paid', align: 'center' },
      ],
    };
  },
};
</script>
