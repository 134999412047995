










import Vue from 'vue';
import LoginForm from '../components/LoginForm/LoginForm.vue';

export default Vue.extend({
  components: {
    LoginForm,
  },
});
