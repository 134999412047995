import axiosClient from '@/config/axios.configs';
import { IQuestionPack } from '@/components/DefaultSetting/interface/questionPack.interface';

class QuestionPackConfService {
  async getDetail(): Promise<{
    ok: boolean;
    data: IQuestionPack;
  }> {
    try {
      const res = await axiosClient.get(`bot-config/question-pack/`);
      return {
        ok: true,
        data: res.data,
      };
    } catch (error) {
      console.log(error);
      return {
        ok: true,
        data: error,
      };
    }
  }

  async updateDetail(data: IQuestionPack) {
    try {
      const res = await axiosClient.post(`bot-config/question-pack/`, data);
      return res.data;
    } catch (error) {
      console.log(error);
      return { error: true };
    }
  }
}

export default new QuestionPackConfService();
