









import Vue from 'vue';
import ToolBar from '../components/ToolBar/ToolBar.vue';
import NavBar from '../components/NavBar/NavBar.vue';

export default Vue.extend({
  name: 'Home',
  components: {
    ToolBar,
    NavBar,
  },
  data: () => {
    return {
      drawer: false,
    };
  },
  methods: {
    switchDrawer(drawer: boolean) {
      this.drawer = drawer;
    },
  },
});
