import { IDefaultDate } from '@/components/DefaultSetting/interface/defaultDate.interface';
import axiosClient from '@/config/axios.configs';

class DefaultDateConfService {
  async getDetail(): Promise<{
    ok: boolean;
    data: IDefaultDate[];
  }> {
    try {
      const res = await axiosClient.get(`bot-config/default-date/`);
      return {
        ok: true,
        data: res.data.date,
      };
    } catch (error) {
      console.log(error);
      return {
        ok: true,
        data: error,
      };
    }
  }

  async updateDetail(data: IDefaultDate[]) {
    try {
      const res = await axiosClient.post(`bot-config/default-date/`, data);
      return res.data;
    } catch (error) {
      console.log(error);
      return { error: true };
    }
  }
}

export default new DefaultDateConfService();
