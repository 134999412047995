













import Vue from 'vue';
export default Vue.extend({
  props: {
    dataTable: {
      required: true,
      type: Array,
    },
  },
  data: () => {
    return {
      series: [55, 23, 14, 12],
      chartOptions: {
        labels: ['Free', 'Standard', 'Premium', 'Enterprise'],
        chart: {
          type: 'donut',
        },
        legend: {
          position: 'bottom',
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            donut: {
              background: 'transparent',
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: '22px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 600,
                  color: undefined,
                  offsetY: -10,
                },
                value: {
                  show: true,
                  fontSize: '16px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 400,
                  color: undefined,
                  offsetY: 16,
                },
                total: {
                  show: true,
                  label: 'Total',
                  fontSize: '22px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 600,
                  color: '#373d3f',
                },
              },
            },
          },
        },
      },
    };
  },
});
