











import Vue from 'vue';
export default Vue.extend({
  props: {
    text: {
      required: true,
      type: String,
      default: 'No message found',
    },
    show: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show(newVal: boolean, oldVal: boolean) {
      if (newVal !== oldVal) this.showNotification = newVal;
    },
    showNotification(newVal: boolean) {
      if (!newVal) {
        this.close();
      }
    },
  },
  data: () => {
    return {
      color: 'default',
      timeout: 5000,
      showNotification: false,
    };
  },
  methods: {
    close() {
      this.$emit('closeNotifyBar');
    },
  },
});
