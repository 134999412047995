

















import Vue from 'vue';
import BasicNotifyConfig from './components/BasicNotifyConfig.vue';
import RecurringNotifyConfig from './components/RecurringNotifyConfig.vue';
import GeneralChannelNotifyConfig from './components/GeneralChannelNotifyConfig.vue';
import BirthdayNotifyConfig from './components/BirthdayNotifyConfig.vue';
import DefaultDateConfig from './components/DefaultDateConfig.vue';
import QuestionPackConfig from './components/QuestionPackDefaultConfig.vue';

export default Vue.extend({
  components: {
    BasicNotifyConfig,
    RecurringNotifyConfig,
    GeneralChannelNotifyConfig,
    BirthdayNotifyConfig,
    DefaultDateConfig,
    QuestionPackConfig,
  },
});
