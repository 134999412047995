



















































































































import Vue from 'vue';
import BasicNotiConfService from '@/services/default-config/basicNotiConf.service';

export default Vue.extend({
  data() {
    return {
      dataLoading: true,
      formEditing: false,
      formData: {
        default: false,
        time: {
          hour: '08',
          minute: '30',
        },
        weekday: ['Mon', 'Tue', 'Wed'],
        message:
          "Hi! It's time for today Daily Standup! Feel free to type `report` now?",
      },
      hours: [
        '00',
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23',
      ],
      minutes: [
        '00',
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23',
        '24',
        '25',
        '26',
        '27',
        '28',
        '29',
        '30',
        '31',
        '32',
        '33',
        '34',
        '35',
        '36',
        '37',
        '38',
        '39',
        '40',
        '41',
        '42',
        '43',
        '44',
        '45',
        '46',
        '47',
        '48',
        '49',
        '50',
        '51',
        '52',
        '53',
        '54',
        '55',
        '56',
        '57',
        '58',
        '59',
      ],
    };
  },
  created() {
    this.initiate();
  },
  methods: {
    async initiate() {
      const data = await BasicNotiConfService.getDetail();
      if (data.ok) {
        if (!data.data.message) {
          await BasicNotiConfService.updateDetail(this.formData);
          return;
        }
        this.formData = data.data;
      }
      this.dataLoading = false;
    },

    async update() {
      if (this.formEditing) {
        this.dataLoading = true;
        await BasicNotiConfService.updateDetail(this.formData);
        await this.initiate();
        this.dataLoading = false;
      }
      this.formEditing = !this.formEditing;
    },
  },
});
