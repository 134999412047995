







































































import Vue from 'vue';
import PaymentPackageService from '@/services/payment-package/paymentPackage.service';
import CreateNewPackageModal from './modals/createNewPackageModal.vue';
import UpdatePackageModal from './modals/updatePackageModal.vue';
import NotifyBar from '../NotifyBar/NotifyBar.vue';
import { IPackage } from './interface/paymentPackage.interface';
import { IResponse } from '@/interfaces/response.interface';

export default Vue.extend({
  components: {
    CreateNewPackageModal,
    UpdatePackageModal,
    NotifyBar,
  },
  data: () => {
    return {
      headers: [
        {
          text: 'Name',
          align: 'center',
          value: 'name',
          divider: true,
          sortable: false,
        },
        { text: 'Value($)', align: 'center', value: 'value' },
        {
          text: 'Interval',
          align: 'center',
          value: 'interval',
          sortable: false,
          filterable: true,
        },
        { text: 'Subscriptors', align: 'center', value: 'subscriptors' },
        { text: 'Active', align: 'center', value: 'active', sortable: false },
        {
          text: 'Trial',
          align: 'center',
          value: 'trial.enable',
          sortable: false,
        },
        {
          text: 'Action',
          align: 'center',
          value: 'actions',
          sortable: false,
        },
      ],
      listPackages: [],
      showCreateModal: false,
      showUpdateModal: false,
      notifyBar: {
        show: false,
        text: '',
      },
      pkgToUpdate: {},
    };
  },
  created() {
    this.getAllPackage();
  },
  computed: {
    dialogResize() {
      let width = '30%';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          width = '100%';
          break;
        case 'sm':
          width = '100%';
          break;
        case 'md':
          width = '80%';
          break;
        default:
          width = '60%';
          break;
      }
      return width;
    },
  },
  methods: {
    openCreatePackageModal() {
      this.showCreateModal = true;
    },
    closeCreatePackageModal() {
      this.showCreateModal = false;
    },
    openUpdatePackageModal(item: IPackage) {
      this.pkgToUpdate = JSON.parse(JSON.stringify(item));
      this.showUpdateModal = true;
    },
    closeUpdatePackageModal() {
      this.showUpdateModal = false;
    },
    closeNotifyBar() {
      this.notifyBar.show = false;
    },
    async getAllPackage() {
      const res: IResponse = await PaymentPackageService.getAllPackage();
      if (res.error) {
        this.notifyBar.text = res.message || "There's something wrong";
        this.notifyBar.show = true;
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.listPackages = res.data.map((pkg: any) => {
          pkg.value = pkg.prices[pkg.prices.length - 1].value;
          return pkg;
        });
      }
    },
    async createNewPackage(data: IPackage) {
      const res: IResponse = await PaymentPackageService.addOnePackage(data);
      if (res.error) {
        this.notifyBar.text = res.message || "There's something wrong";
        this.notifyBar.show = true;
      } else {
        this.closeCreatePackageModal();
        this.getAllPackage();
      }
    },
    async updatePackage(data: IPackage) {
      const res: IResponse = await PaymentPackageService.updateOnePackage(data);
      if (res.error) {
        this.notifyBar.text = res.message || "There's something wrong";
        this.notifyBar.show = true;
      } else {
        this.closeUpdatePackageModal();
        this.getAllPackage();
      }
    },
  },
});
