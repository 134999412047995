































































import Vue from 'vue';
import TextEditor from '../../TextEditor/TextEditor.vue';

export default Vue.extend({
  props: {
    pkg: {
      required: true,
      type: Object,
    },
  },
  components: {
    TextEditor,
  },
  watch: {
    pkg() {
      this.updatePaymentPackage = JSON.parse(JSON.stringify(this.pkg));
    },
  },
  created() {
    this.updatePaymentPackage = JSON.parse(JSON.stringify(this.pkg));
  },
  data: () => {
    return {
      textEditorValidate: false,
      rules: {
        required: (value: number | string) => {
          const valid = !!value || value == 0;
          return valid || 'This field is required';
        },
        largerThanZero: (value: number) => value > 0 || 'Choose larger number',
      },
      updatePaymentPackage: {
        _id: '',
        name: '',
        description: '',
        value: 0.0,
        trial: {
          enable: false,
          duration: 30,
        },
      },
    };
  },
  computed: {
    form(): Vue & { validate: () => boolean } {
      return this.$refs.formUpdatePlan as Vue & { validate: () => boolean };
    },
  },
  methods: {
    updateOnePackage() {
      const compareChangement =
        JSON.stringify(this.updatePaymentPackage) === JSON.stringify(this.pkg);

      if (this.form.validate()) {
        if (!compareChangement) {
          this.$emit('updatePackage', this.updatePaymentPackage);
        } else {
          this.cancel();
        }
      }
    },
    updateTextEditorContent(content: string) {
      this.updatePaymentPackage.description = content;
    },
    cancel() {
      this.$emit('closeUpdatePackageModal');
    },
  },
});
