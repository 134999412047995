import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import HomeRoutes from './home.routes';
import ErrorRoutes from './error.routes';
import AuthRoutes from './auth.routes';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [AuthRoutes, HomeRoutes, ErrorRoutes];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
export default router;
