







































































































import Vue from 'vue';
import DefaultDateConfService from '@/services/default-config/defaultDateConf.service';

export default Vue.extend({
  data() {
    return {
      dataLoading: true,
      formEditing: false,
      dayChoosen: [
        {
          month: '01',
          name: 'Jan',
          day: '01',
        },
        {
          month: '04',
          name: 'April',
          day: '30',
        },
        {
          month: '05',
          name: 'May',
          day: '01',
        },
        {
          month: '09',
          name: 'Sep',
          day: '02',
        },
      ],
      aYear: [
        {
          name: 'Jan',
          value: '01',
          days: [
            {
              day: 1,
              choosen: false,
            },
            {
              day: 2,
              choosen: false,
            },
            {
              day: 3,
              choosen: false,
            },
            {
              day: 4,
              choosen: false,
            },
            {
              day: 5,
              choosen: false,
            },
            {
              day: 6,
              choosen: false,
            },
            {
              day: 7,
              choosen: false,
            },
            {
              day: 8,
              choosen: false,
            },
            {
              day: 9,
              choosen: false,
            },
            {
              day: 10,
              choosen: false,
            },
            {
              day: 11,
              choosen: false,
            },
            {
              day: 12,
              choosen: false,
            },
            {
              day: 13,
              choosen: false,
            },
            {
              day: 14,
              choosen: false,
            },
            {
              day: 15,
              choosen: false,
            },
            {
              day: 16,
              choosen: false,
            },
            {
              day: 17,
              choosen: false,
            },
            {
              day: 18,
              choosen: false,
            },
            {
              day: 19,
              choosen: false,
            },
            {
              day: 20,
              choosen: false,
            },
            {
              day: 21,
              choosen: false,
            },
            {
              day: 22,
              choosen: false,
            },
            {
              day: 23,
              choosen: false,
            },
            {
              day: 24,
              choosen: false,
            },
            {
              day: 25,
              choosen: false,
            },
            {
              day: 26,
              choosen: false,
            },
            {
              day: 27,
              choosen: false,
            },
            {
              day: 28,
              choosen: false,
            },
            {
              day: 29,
              choosen: false,
            },
            {
              day: 30,
              choosen: false,
            },
            {
              day: 31,
              choosen: false,
            },
          ],
        },
        {
          name: 'Feb',
          value: '02',
          days: [
            {
              day: 1,
              choosen: false,
            },
            {
              day: 2,
              choosen: false,
            },
            {
              day: 3,
              choosen: false,
            },
            {
              day: 4,
              choosen: false,
            },
            {
              day: 5,
              choosen: false,
            },
            {
              day: 6,
              choosen: false,
            },
            {
              day: 7,
              choosen: false,
            },
            {
              day: 8,
              choosen: false,
            },
            {
              day: 9,
              choosen: false,
            },
            {
              day: 10,
              choosen: false,
            },
            {
              day: 11,
              choosen: false,
            },
            {
              day: 12,
              choosen: false,
            },
            {
              day: 13,
              choosen: false,
            },
            {
              day: 14,
              choosen: false,
            },
            {
              day: 15,
              choosen: false,
            },
            {
              day: 16,
              choosen: false,
            },
            {
              day: 17,
              choosen: false,
            },
            {
              day: 18,
              choosen: false,
            },
            {
              day: 19,
              choosen: false,
            },
            {
              day: 20,
              choosen: false,
            },
            {
              day: 21,
              choosen: false,
            },
            {
              day: 22,
              choosen: false,
            },
            {
              day: 23,
              choosen: false,
            },
            {
              day: 24,
              choosen: false,
            },
            {
              day: 25,
              choosen: false,
            },
            {
              day: 26,
              choosen: false,
            },
            {
              day: 27,
              choosen: false,
            },
            {
              day: 28,
              choosen: false,
            },
            {
              day: 29,
              choosen: false,
            },
          ],
        },
        {
          name: 'Mar',
          value: '03',
          days: [
            {
              day: 1,
              choosen: false,
            },
            {
              day: 2,
              choosen: false,
            },
            {
              day: 3,
              choosen: false,
            },
            {
              day: 4,
              choosen: false,
            },
            {
              day: 5,
              choosen: false,
            },
            {
              day: 6,
              choosen: false,
            },
            {
              day: 7,
              choosen: false,
            },
            {
              day: 8,
              choosen: false,
            },
            {
              day: 9,
              choosen: false,
            },
            {
              day: 10,
              choosen: false,
            },
            {
              day: 11,
              choosen: false,
            },
            {
              day: 12,
              choosen: false,
            },
            {
              day: 13,
              choosen: false,
            },
            {
              day: 14,
              choosen: false,
            },
            {
              day: 15,
              choosen: false,
            },
            {
              day: 16,
              choosen: false,
            },
            {
              day: 17,
              choosen: false,
            },
            {
              day: 18,
              choosen: false,
            },
            {
              day: 19,
              choosen: false,
            },
            {
              day: 20,
              choosen: false,
            },
            {
              day: 21,
              choosen: false,
            },
            {
              day: 22,
              choosen: false,
            },
            {
              day: 23,
              choosen: false,
            },
            {
              day: 24,
              choosen: false,
            },
            {
              day: 25,
              choosen: false,
            },
            {
              day: 26,
              choosen: false,
            },
            {
              day: 27,
              choosen: false,
            },
            {
              day: 28,
              choosen: false,
            },
            {
              day: 29,
              choosen: false,
            },
            {
              day: 30,
              choosen: false,
            },
            {
              day: 31,
              choosen: false,
            },
          ],
        },
        {
          name: 'Apr',
          value: '04',
          days: [
            {
              day: 1,
              choosen: false,
            },
            {
              day: 2,
              choosen: false,
            },
            {
              day: 3,
              choosen: false,
            },
            {
              day: 4,
              choosen: false,
            },
            {
              day: 5,
              choosen: false,
            },
            {
              day: 6,
              choosen: false,
            },
            {
              day: 7,
              choosen: false,
            },
            {
              day: 8,
              choosen: false,
            },
            {
              day: 9,
              choosen: false,
            },
            {
              day: 10,
              choosen: false,
            },
            {
              day: 11,
              choosen: false,
            },
            {
              day: 12,
              choosen: false,
            },
            {
              day: 13,
              choosen: false,
            },
            {
              day: 14,
              choosen: false,
            },
            {
              day: 15,
              choosen: false,
            },
            {
              day: 16,
              choosen: false,
            },
            {
              day: 17,
              choosen: false,
            },
            {
              day: 18,
              choosen: false,
            },
            {
              day: 19,
              choosen: false,
            },
            {
              day: 20,
              choosen: false,
            },
            {
              day: 21,
              choosen: false,
            },
            {
              day: 22,
              choosen: false,
            },
            {
              day: 23,
              choosen: false,
            },
            {
              day: 24,
              choosen: false,
            },
            {
              day: 25,
              choosen: false,
            },
            {
              day: 26,
              choosen: false,
            },
            {
              day: 27,
              choosen: false,
            },
            {
              day: 28,
              choosen: false,
            },
            {
              day: 29,
              choosen: false,
            },
            {
              day: 30,
              choosen: false,
            },
          ],
        },
        {
          name: 'May',
          value: '05',
          days: [
            {
              day: 1,
              choosen: false,
            },
            {
              day: 2,
              choosen: false,
            },
            {
              day: 3,
              choosen: false,
            },
            {
              day: 4,
              choosen: false,
            },
            {
              day: 5,
              choosen: false,
            },
            {
              day: 6,
              choosen: false,
            },
            {
              day: 7,
              choosen: false,
            },
            {
              day: 8,
              choosen: false,
            },
            {
              day: 9,
              choosen: false,
            },
            {
              day: 10,
              choosen: false,
            },
            {
              day: 11,
              choosen: false,
            },
            {
              day: 12,
              choosen: false,
            },
            {
              day: 13,
              choosen: false,
            },
            {
              day: 14,
              choosen: false,
            },
            {
              day: 15,
              choosen: false,
            },
            {
              day: 16,
              choosen: false,
            },
            {
              day: 17,
              choosen: false,
            },
            {
              day: 18,
              choosen: false,
            },
            {
              day: 19,
              choosen: false,
            },
            {
              day: 20,
              choosen: false,
            },
            {
              day: 21,
              choosen: false,
            },
            {
              day: 22,
              choosen: false,
            },
            {
              day: 23,
              choosen: false,
            },
            {
              day: 24,
              choosen: false,
            },
            {
              day: 25,
              choosen: false,
            },
            {
              day: 26,
              choosen: false,
            },
            {
              day: 27,
              choosen: false,
            },
            {
              day: 28,
              choosen: false,
            },
            {
              day: 29,
              choosen: false,
            },
            {
              day: 30,
              choosen: false,
            },
            {
              day: 31,
              choosen: false,
            },
          ],
        },
        {
          name: 'Jun',
          value: '06',
          days: [
            {
              day: 1,
              choosen: false,
            },
            {
              day: 2,
              choosen: false,
            },
            {
              day: 3,
              choosen: false,
            },
            {
              day: 4,
              choosen: false,
            },
            {
              day: 5,
              choosen: false,
            },
            {
              day: 6,
              choosen: false,
            },
            {
              day: 7,
              choosen: false,
            },
            {
              day: 8,
              choosen: false,
            },
            {
              day: 9,
              choosen: false,
            },
            {
              day: 10,
              choosen: false,
            },
            {
              day: 11,
              choosen: false,
            },
            {
              day: 12,
              choosen: false,
            },
            {
              day: 13,
              choosen: false,
            },
            {
              day: 14,
              choosen: false,
            },
            {
              day: 15,
              choosen: false,
            },
            {
              day: 16,
              choosen: false,
            },
            {
              day: 17,
              choosen: false,
            },
            {
              day: 18,
              choosen: false,
            },
            {
              day: 19,
              choosen: false,
            },
            {
              day: 20,
              choosen: false,
            },
            {
              day: 21,
              choosen: false,
            },
            {
              day: 22,
              choosen: false,
            },
            {
              day: 23,
              choosen: false,
            },
            {
              day: 24,
              choosen: false,
            },
            {
              day: 25,
              choosen: false,
            },
            {
              day: 26,
              choosen: false,
            },
            {
              day: 27,
              choosen: false,
            },
            {
              day: 28,
              choosen: false,
            },
            {
              day: 29,
              choosen: false,
            },
            {
              day: 30,
              choosen: false,
            },
          ],
        },
        {
          name: 'July',
          value: '07',
          days: [
            {
              day: 1,
              choosen: false,
            },
            {
              day: 2,
              choosen: false,
            },
            {
              day: 3,
              choosen: false,
            },
            {
              day: 4,
              choosen: false,
            },
            {
              day: 5,
              choosen: false,
            },
            {
              day: 6,
              choosen: false,
            },
            {
              day: 7,
              choosen: false,
            },
            {
              day: 8,
              choosen: false,
            },
            {
              day: 9,
              choosen: false,
            },
            {
              day: 10,
              choosen: false,
            },
            {
              day: 11,
              choosen: false,
            },
            {
              day: 12,
              choosen: false,
            },
            {
              day: 13,
              choosen: false,
            },
            {
              day: 14,
              choosen: false,
            },
            {
              day: 15,
              choosen: false,
            },
            {
              day: 16,
              choosen: false,
            },
            {
              day: 17,
              choosen: false,
            },
            {
              day: 18,
              choosen: false,
            },
            {
              day: 19,
              choosen: false,
            },
            {
              day: 20,
              choosen: false,
            },
            {
              day: 21,
              choosen: false,
            },
            {
              day: 22,
              choosen: false,
            },
            {
              day: 23,
              choosen: false,
            },
            {
              day: 24,
              choosen: false,
            },
            {
              day: 25,
              choosen: false,
            },
            {
              day: 26,
              choosen: false,
            },
            {
              day: 27,
              choosen: false,
            },
            {
              day: 28,
              choosen: false,
            },
            {
              day: 29,
              choosen: false,
            },
            {
              day: 30,
              choosen: false,
            },
            {
              day: 31,
              choosen: false,
            },
          ],
        },
        {
          name: 'Aug',
          value: '08',
          days: [
            {
              day: 1,
              choosen: false,
            },
            {
              day: 2,
              choosen: false,
            },
            {
              day: 3,
              choosen: false,
            },
            {
              day: 4,
              choosen: false,
            },
            {
              day: 5,
              choosen: false,
            },
            {
              day: 6,
              choosen: false,
            },
            {
              day: 7,
              choosen: false,
            },
            {
              day: 8,
              choosen: false,
            },
            {
              day: 9,
              choosen: false,
            },
            {
              day: 10,
              choosen: false,
            },
            {
              day: 11,
              choosen: false,
            },
            {
              day: 12,
              choosen: false,
            },
            {
              day: 13,
              choosen: false,
            },
            {
              day: 14,
              choosen: false,
            },
            {
              day: 15,
              choosen: false,
            },
            {
              day: 16,
              choosen: false,
            },
            {
              day: 17,
              choosen: false,
            },
            {
              day: 18,
              choosen: false,
            },
            {
              day: 19,
              choosen: false,
            },
            {
              day: 20,
              choosen: false,
            },
            {
              day: 21,
              choosen: false,
            },
            {
              day: 22,
              choosen: false,
            },
            {
              day: 23,
              choosen: false,
            },
            {
              day: 24,
              choosen: false,
            },
            {
              day: 25,
              choosen: false,
            },
            {
              day: 26,
              choosen: false,
            },
            {
              day: 27,
              choosen: false,
            },
            {
              day: 28,
              choosen: false,
            },
            {
              day: 29,
              choosen: false,
            },
            {
              day: 30,
              choosen: false,
            },
            {
              day: 31,
              choosen: false,
            },
          ],
        },
        {
          name: 'Sep',
          value: '09',
          days: [
            {
              day: 1,
              choosen: false,
            },
            {
              day: 2,
              choosen: false,
            },
            {
              day: 3,
              choosen: false,
            },
            {
              day: 4,
              choosen: false,
            },
            {
              day: 5,
              choosen: false,
            },
            {
              day: 6,
              choosen: false,
            },
            {
              day: 7,
              choosen: false,
            },
            {
              day: 8,
              choosen: false,
            },
            {
              day: 9,
              choosen: false,
            },
            {
              day: 10,
              choosen: false,
            },
            {
              day: 11,
              choosen: false,
            },
            {
              day: 12,
              choosen: false,
            },
            {
              day: 13,
              choosen: false,
            },
            {
              day: 14,
              choosen: false,
            },
            {
              day: 15,
              choosen: false,
            },
            {
              day: 16,
              choosen: false,
            },
            {
              day: 17,
              choosen: false,
            },
            {
              day: 18,
              choosen: false,
            },
            {
              day: 19,
              choosen: false,
            },
            {
              day: 20,
              choosen: false,
            },
            {
              day: 21,
              choosen: false,
            },
            {
              day: 22,
              choosen: false,
            },
            {
              day: 23,
              choosen: false,
            },
            {
              day: 24,
              choosen: false,
            },
            {
              day: 25,
              choosen: false,
            },
            {
              day: 26,
              choosen: false,
            },
            {
              day: 27,
              choosen: false,
            },
            {
              day: 28,
              choosen: false,
            },
            {
              day: 29,
              choosen: false,
            },
            {
              day: 30,
              choosen: false,
            },
          ],
        },
        {
          name: 'Oct',
          value: '10',
          days: [
            {
              day: 1,
              choosen: false,
            },
            {
              day: 2,
              choosen: false,
            },
            {
              day: 3,
              choosen: false,
            },
            {
              day: 4,
              choosen: false,
            },
            {
              day: 5,
              choosen: false,
            },
            {
              day: 6,
              choosen: false,
            },
            {
              day: 7,
              choosen: false,
            },
            {
              day: 8,
              choosen: false,
            },
            {
              day: 9,
              choosen: false,
            },
            {
              day: 10,
              choosen: false,
            },
            {
              day: 11,
              choosen: false,
            },
            {
              day: 12,
              choosen: false,
            },
            {
              day: 13,
              choosen: false,
            },
            {
              day: 14,
              choosen: false,
            },
            {
              day: 15,
              choosen: false,
            },
            {
              day: 16,
              choosen: false,
            },
            {
              day: 17,
              choosen: false,
            },
            {
              day: 18,
              choosen: false,
            },
            {
              day: 19,
              choosen: false,
            },
            {
              day: 20,
              choosen: false,
            },
            {
              day: 21,
              choosen: false,
            },
            {
              day: 22,
              choosen: false,
            },
            {
              day: 23,
              choosen: false,
            },
            {
              day: 24,
              choosen: false,
            },
            {
              day: 25,
              choosen: false,
            },
            {
              day: 26,
              choosen: false,
            },
            {
              day: 27,
              choosen: false,
            },
            {
              day: 28,
              choosen: false,
            },
            {
              day: 29,
              choosen: false,
            },
            {
              day: 30,
              choosen: false,
            },
            {
              day: 31,
              choosen: false,
            },
          ],
        },
        {
          name: 'Nov',
          value: '11',
          days: [
            {
              day: 1,
              choosen: false,
            },
            {
              day: 2,
              choosen: false,
            },
            {
              day: 3,
              choosen: false,
            },
            {
              day: 4,
              choosen: false,
            },
            {
              day: 5,
              choosen: false,
            },
            {
              day: 6,
              choosen: false,
            },
            {
              day: 7,
              choosen: false,
            },
            {
              day: 8,
              choosen: false,
            },
            {
              day: 9,
              choosen: false,
            },
            {
              day: 10,
              choosen: false,
            },
            {
              day: 11,
              choosen: false,
            },
            {
              day: 12,
              choosen: false,
            },
            {
              day: 13,
              choosen: false,
            },
            {
              day: 14,
              choosen: false,
            },
            {
              day: 15,
              choosen: false,
            },
            {
              day: 16,
              choosen: false,
            },
            {
              day: 17,
              choosen: false,
            },
            {
              day: 18,
              choosen: false,
            },
            {
              day: 19,
              choosen: false,
            },
            {
              day: 20,
              choosen: false,
            },
            {
              day: 21,
              choosen: false,
            },
            {
              day: 22,
              choosen: false,
            },
            {
              day: 23,
              choosen: false,
            },
            {
              day: 24,
              choosen: false,
            },
            {
              day: 25,
              choosen: false,
            },
            {
              day: 26,
              choosen: false,
            },
            {
              day: 27,
              choosen: false,
            },
            {
              day: 28,
              choosen: false,
            },
            {
              day: 29,
              choosen: false,
            },
            {
              day: 30,
              choosen: false,
            },
          ],
        },
        {
          name: 'Dec',
          value: '12',
          days: [
            {
              day: 1,
              choosen: false,
            },
            {
              day: 2,
              choosen: false,
            },
            {
              day: 3,
              choosen: false,
            },
            {
              day: 4,
              choosen: false,
            },
            {
              day: 5,
              choosen: false,
            },
            {
              day: 6,
              choosen: false,
            },
            {
              day: 7,
              choosen: false,
            },
            {
              day: 8,
              choosen: false,
            },
            {
              day: 9,
              choosen: false,
            },
            {
              day: 10,
              choosen: false,
            },
            {
              day: 11,
              choosen: false,
            },
            {
              day: 12,
              choosen: false,
            },
            {
              day: 13,
              choosen: false,
            },
            {
              day: 14,
              choosen: false,
            },
            {
              day: 15,
              choosen: false,
            },
            {
              day: 16,
              choosen: false,
            },
            {
              day: 17,
              choosen: false,
            },
            {
              day: 18,
              choosen: false,
            },
            {
              day: 19,
              choosen: false,
            },
            {
              day: 20,
              choosen: false,
            },
            {
              day: 21,
              choosen: false,
            },
            {
              day: 22,
              choosen: false,
            },
            {
              day: 23,
              choosen: false,
            },
            {
              day: 24,
              choosen: false,
            },
            {
              day: 25,
              choosen: false,
            },
            {
              day: 26,
              choosen: false,
            },
            {
              day: 27,
              choosen: false,
            },
            {
              day: 28,
              choosen: false,
            },
            {
              day: 29,
              choosen: false,
            },
            {
              day: 30,
              choosen: false,
            },
            {
              day: 31,
              choosen: false,
            },
          ],
        },
      ],
    };
  },
  async created() {
    await this.initiate();
  },
  computed: {
    dateChoosenGrouped() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const groupByMonth = this.$data.dayChoosen.reduce((r: any, a: any) => {
        r[a.month] = [...(r[a.month] || []), a];
        return r;
      }, {});
      return groupByMonth;
    },
  },
  methods: {
    async initiate() {
      let data = await DefaultDateConfService.getDetail();
      if (data.ok) {
        if (!data.data[0]) {
          await DefaultDateConfService.updateDetail(this.dayChoosen);
          data = await DefaultDateConfService.getDetail();
        }
        this.dayChoosen = data.data;
        this.aYear = this.aYear.map(e => {
          this.dayChoosen.forEach(d => {
            if (d.month === e.value) {
              e.days[Number(d.day) - 1].choosen = true;
            }
          });
          return e;
        });
      }
      this.dataLoading = false;
    },

    async update() {
      if (this.formEditing) {
        this.dataLoading = true;
        await DefaultDateConfService.updateDetail(this.dayChoosen);
        await this.initiate();
        this.dataLoading = false;
      }
      this.formEditing = !this.formEditing;
    },
    aDateClicked() {
      this.dayChoosen = [];
      this.aYear.forEach(e => {
        e.days.forEach(d => {
          if (d.choosen) {
            this.dayChoosen.push({
              month: e.value,
              name: e.name,
              day: d.day.toString(),
            });
          }
        });
      });
    },
  },
});
