













































































import Vue from 'vue';
import TextEditor from '../../TextEditor/TextEditor.vue';

export default Vue.extend({
  components: {
    TextEditor,
  },
  data: () => {
    return {
      textEditorValidate: false,
      rules: {
        required: (value: number | string) => {
          const valid = !!value || value == 0;
          return valid || 'This field is required';
        },
        largerThanZero: (value: number) => value > 0 || 'Choose larger number',
      },
      intervals: [
        { text: 'Monthly', value: 'month' },
        { text: 'Yearly', value: 'year' },
      ],
      newPaymentPackage: {
        name: '',
        value: '',
        interval: '',
        description: '',
        trial: {
          enable: false,
          duration: 30,
        },
      },
    };
  },
  mounted() {
    this.form.reset();
  },
  computed: {
    form(): Vue & { validate: () => boolean } & { reset: () => void } & {
      resetValidation: () => void;
    } {
      return this.$refs.formCreatePlan as Vue & { validate: () => boolean } & {
        reset: () => void;
      } & {
        resetValidation: () => void;
      };
    },
  },
  methods: {
    async createOnePackage() {
      const valid = this.form.validate();
      this.textEditorValidate = true;
      if (valid && this.newPaymentPackage.description !== '') {
        this.$emit('createNewPackage', {
          name: this.newPaymentPackage.name,
          value: this.newPaymentPackage.value,
          interval: this.newPaymentPackage.interval,
          description: this.newPaymentPackage.description,
          trial: {
            enable: this.newPaymentPackage.trial.enable,
            duration: this.newPaymentPackage.trial.duration,
          },
        });
        this.close();
      }
    },
    updateTextEditorContent(content: string) {
      this.newPaymentPackage.description = content;
    },
    close() {
      this.$emit('closeCreatePackageModal');
      this.newPaymentPackage.description = '';
      this.form.reset();
    },
  },
});
