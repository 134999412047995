



















































import Vue from 'vue';
import AuthService from '@/services/auth/auth.service';
import NotifyBar from '../NotifyBar/NotifyBar.vue';

export default Vue.extend({
  components: {
    NotifyBar,
  },
  data: () => {
    return {
      loginForm: {
        rules: {
          required: (value: string) => !!value || 'Required!',
          min: (v: string[]) => v.length >= 8 || 'Min 8 characters',
        },
        username: '',
        password: '',
        passwordShow: false,
      },
      alertBar: {
        show: false,
        text: "You're logged in",
      },
    };
  },
  computed: {
    form(): Vue & { validate: () => boolean } {
      return this.$refs.loginForm as Vue & { validate: () => boolean };
    },
  },
  methods: {
    async logInWithAccount() {
      const validate = this.form.validate();
      if (validate) {
        const userInput = {
          username: this.loginForm.username,
          password: this.loginForm.password,
        };
        const res = await AuthService.logIn(userInput);
        if (res.error) {
          this.alertBar.text = res.message;
          this.alertBar.show = true;
        }
      }
    },
    closeNotifyBar() {
      this.alertBar.show = false;
    },
  },
});
