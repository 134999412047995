import { IChannelNotify } from '@/components/DefaultSetting/interface/channelNotiConf.interface';
import axiosClient from '@/config/axios.configs';

class ChannelNotiConfService {
  async getDetail(): Promise<{
    ok: boolean;
    data: IChannelNotify;
  }> {
    try {
      const res = await axiosClient.get(`bot-config/channel-notify/`);
      return {
        ok: true,
        data: res.data,
      };
    } catch (error) {
      console.log(error);
      return {
        ok: true,
        data: error,
      };
    }
  }

  async updateDetail(data: IChannelNotify) {
    try {
      const res = await axiosClient.post(`bot-config/channel-notify/`, data);
      return res.data;
    } catch (error) {
      console.log(error);
      return { error: true };
    }
  }
}

export default new ChannelNotiConfService();
