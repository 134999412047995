
















































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue';
import CustomerService from '@/services/customer/customer.service';

export default Vue.extend({
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'Customer Workspace Id',
          align: 'start',
          filterable: false,
          divider: true,
          value: 'workspaceId',
        },
        { text: 'Email', value: 'email', align: 'center' },
        {
          text: 'Stripe Customer Id',
          value: 'stripeCustomerId',
          align: 'center',
        },
        { text: 'On billing', value: 'onBilling', align: 'center' },
        {
          text: 'Active Member',
          value: 'currentActiveMembers',
          align: 'center',
        },
        {
          text: 'Current Package',
          value: 'currentPlan.packageName',
          align: 'center',
        },
        {
          text: 'Billing History',
          value: 'planHistory',
          align: 'center',
        },
      ],
      actions: [
        { title: 'Click Me' },
        { title: 'Click Me' },
        { title: 'Click Me' },
        { title: 'Click Me 2' },
      ],
      data: [],
    };
  },
  mounted() {
    this.getAllCustomers();
  },
  methods: {
    async getAllCustomers() {
      const customers = await CustomerService.getListCustomers();
      this.data = customers.data.map((customer: any) => {
        customer.currentPlan =
          customer.planHistory[customer.planHistory.length - 1];
        return customer;
      });
    },
    console(data: any) {
      console.log(data);
    },
    handleClickRow(value: any) {
      this.$router.push(`customer/${value.workspaceId}`);
    },
  },
});
