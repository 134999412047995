import { IBasicNotify } from '@/components/DefaultSetting/interface/basicNotiConf.interface';
import axiosClient from '@/config/axios.configs';

class BasicNotiConfService {
  async getDetail(): Promise<{
    ok: boolean;
    data: IBasicNotify;
  }> {
    try {
      const res = await axiosClient.get(`bot-config/basic-notify/`);
      return {
        ok: true,
        data: res.data,
      };
    } catch (error) {
      console.log(error);
      return {
        ok: true,
        data: error,
      };
    }
  }

  async updateDetail(data: IBasicNotify) {
    try {
      const res = await axiosClient.post(`bot-config/basic-notify/`, data);
      return res.data;
    } catch (error) {
      console.log(error);
      return { error: true };
    }
  }
}

export default new BasicNotiConfService();
