import axiosClient from '@/config/axios.configs';

class DashboardService {
  async getWorkspaceData() {
    try {
      const res = await axiosClient.get('bot/workspaces');
      return res.data;
    } catch (error) {
      console.log(error);
      return {
        error: true,
        data: error,
      };
    }
  }

  async getTeamsOfWorkspaceData(id: string) {
    try {
      const res = await axiosClient.get(`bot/workspaces/teams/${id}`);
      return res.data;
    } catch (error) {
      console.log(error);
      return {
        error: true,
        data: error,
      };
    }
  }

  async getReportOfWorkspaceData(id: string) {
    try {
      const res = await axiosClient.get(`bot/workspaces/reports/${id}`);
      return res.data;
    } catch (error) {
      return {
        error: true,
        data: error,
      };
    }
  }
}

export default new DashboardService();
