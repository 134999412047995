











































































































































































import Vue from 'vue';
import RecurNotiConfService from '@/services/default-config/recurringNotiConf.service';

export default Vue.extend({
  data() {
    return {
      dataLoading: true,
      formEditing: false,
      newMessage: '',
      formData: {
        default: false,
        interval: 20,
        repetition: 2,
        messages: [
          "I haven't received your report. Feel free to type `report` now?",
          "Are you busy? 🤨 Don't forget to `report`",
          "I won't remind you again after receiving your report 🤖. Type `report` now",
          "Hello 😉 Don't forget to `report`",
          "Do you know that you haven't sent your report today? 🤓",
          "I haven't received your report. What's wrong?",
          "Where are you? I'm waiting for your report 😐",
        ],
      },
    };
  },

  created() {
    this.initiate();
  },
  methods: {
    async initiate() {
      const data = await RecurNotiConfService.getDetail();
      if (data.ok) {
        if (!data.data.messages) {
          await RecurNotiConfService.updateDetail(this.formData);
          return;
        }
        this.formData = data.data;
      }
      this.dataLoading = false;
    },
    async update() {
      if (this.formEditing) {
        this.dataLoading = true;
        this.formData.repetition = Number(this.formData.repetition);
        this.formData.interval = Number(this.formData.interval);
        await RecurNotiConfService.updateDetail(this.formData);
        await this.initiate();
        this.dataLoading = false;
      }
      this.formEditing = !this.formEditing;
    },
    duplicateMessage(index: number) {
      this.formData.messages.push(this.formData.messages[index]);
    },
    deleteMessage(index: number) {
      this.formData.messages.splice(index, 1);
    },
    addMessage() {
      this.formData.messages.push(this.newMessage);
      this.newMessage = '';
    },
  },
});
