import { RouteConfig } from 'vue-router';

const routes: RouteConfig = {
  path: '/error',
  component: () => import('../views/Error.vue'),
  children: [
    {
      path: '403',
      component: () => import('../components/Errors/NotAllowed.vue'),
    },
    {
      path: '404',
      component: () => import('../components/Errors/NotFound.vue'),
    },
    {
      path: '500',
      component: () => import('../components/Errors/ServerError.vue'),
    },
  ],
};
export default routes;
