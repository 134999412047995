import {
  IPackage,
  IUpdatePackage,
} from '@/components/PaymentPackage/interface/paymentPackage.interface';
import axiosClient from '@/config/axios.configs';

class PaymentPackageService {
  async getAllPackage() {
    try {
      const listPackages = await axiosClient.get('payment-package');
      return listPackages.data;
    } catch (error) {
      console.log(error);
    }
  }
  async addOnePackage(newPackage: IPackage) {
    try {
      const savedPackage = await axiosClient.post(
        'payment-package',
        newPackage,
      );
      return savedPackage.data;
    } catch (error) {
      console.log(error);
    }
  }
  async getOnePackage(id: string) {
    try {
      const pkg = await axiosClient.get(`payment-package/${id}`);
      return pkg.data;
    } catch (error) {
      console.log(error);
    }
  }

  async updateOnePackage(data: IUpdatePackage) {
    try {
      const pkg = await axiosClient.post(`payment-package/${data._id}`, data);
      return pkg.data;
    } catch (error) {
      console.log(error);
    }
  }
}

export default new PaymentPackageService();
