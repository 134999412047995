





























































import Vue from 'vue';
import ChannelNotifyService from '@/services/default-config/channelNotiConf.service';

export default Vue.extend({
  data() {
    return {
      dataLoading: true,
      formEditing: false,
      formData: {
        default: false,
        message: "I haven't received your reports @name. What's wrong?",
      },
    };
  },
  created() {
    this.initiate();
  },
  methods: {
    async initiate() {
      const data = await ChannelNotifyService.getDetail();
      if (data.ok) {
        if (!data.data.message) {
          await ChannelNotifyService.updateDetail(this.formData);
          return;
        }
        this.formData = data.data;
      }
      this.dataLoading = false;
    },
    async update() {
      if (this.formEditing) {
        this.dataLoading = true;
        await ChannelNotifyService.updateDetail(this.formData);
        await this.initiate();
        this.dataLoading = false;
      }
      this.formEditing = !this.formEditing;
    },
  },
});
