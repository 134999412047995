















import Vue from 'vue';
import VueSimplemde from 'vue-simplemde';
export default Vue.extend({
  components: {
    VueSimplemde,
  },
  props: {
    name: {
      required: true,
      type: String,
      default: 'Text Editor',
    },
    validateRequest: {
      required: false,
      type: Boolean,
      default: false,
    },
    requiredText: {
      required: false,
      type: String,
      default: 'This is required!',
    },
    value: {
      required: true,
      type: String,
      default: '',
    },
  },
  watch: {
    value(value: string) {
      this.content = value;
    },
    validateRequest(_oldVal: boolean, newVal: boolean) {
      if (newVal && this.content === '') {
        this.required = true;
      }
    },
    content(content: string) {
      if (content !== '') {
        this.required = false;
      } else {
        this.required = true;
      }
      this.$emit('updateTextEditorContent', content);
    },
  },
  created() {
    this.content = this.value;
  },
  data: () => {
    return {
      required: false,
      content: '',
      configs: {
        placeholder: 'Type here...',
        toolbar: [
          'bold',
          'italic',
          'strikethrough',
          '|',
          'unordered-list',
          'ordered-list',
          '|',
          'preview',
        ],
        status: false,
      },
    };
  },
});
