import axiosClient from '@/config/axios.configs';

class CustomerService {
  async getListCustomers() {
    try {
      const res = await axiosClient.get('customers/');
      return res.data;
    } catch (error) {
      console.log(error);
      return {
        error: true,
        data: error,
      };
    }
  }

  async getCustomerDetail(workspaceId: string) {
    try {
      const res = await axiosClient.get(`customers/details/${workspaceId}`);
      return res.data;
    } catch (error) {
      console.log(error);
      return {
        error: true,
        data: error,
      };
    }
  }
}

export default new CustomerService();
