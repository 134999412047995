

























import Vue from 'vue';
import authService from '@/services/auth/auth.service';
export default Vue.extend({
  props: {
    drawer: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {};
  },
  methods: {
    logoutUser() {
      authService.logOut();
    },
    switchDrawer() {
      this.$emit('switchDrawer', !this.drawer);
    },
  },
});
